import { Card } from "primereact/card";
import PageTitle from "@components/PageTitle";
import FoldersDataTable from "@components/FoldersDataTable";
import { useGetAllFoldersByLicense } from "@service/FolderService/hooks/useFolderQueries";
import { useKeycloak } from "@react-keycloak/web";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { FoldersEditionManagementPageRoute } from "../../PagesRoutes";
import { useEffect, useState } from "react";
import { FolderProps } from "@service/FolderService/types";

const FolderManagementPage = () => {
  const { initialized } = useKeycloak();
  const navigate = useNavigate();
  const [foldersToTable, setFoldersToTable] = useState<FolderProps[]>([]);

  const { data: folders, isLoading } = useGetAllFoldersByLicense(initialized);

  const mountFolderListToRenderInTable = (filterName: string) => {
    setFoldersToTable(folders?.filter(f => f.name.toLowerCase().indexOf(filterName.toLowerCase()) > -1) ?? []);
  }

  useEffect(() => {
    mountFolderListToRenderInTable("");
  }, [folders]);

  return (
    <Card>
      <PageTitle title="Pastas" icon="pi pi-folder"/>

      <div className="grid p-fluid flex justify-content-end">
        <div className="col-12 md:col-3">
          <Button
            label="Cadastrar Pasta"
            className="p-button-sm"
            icon="bi-plus-lg"
            onClick={() => navigate(FoldersEditionManagementPageRoute)}
          />
        </div>
      </div>

      <FoldersDataTable folders={foldersToTable} loading={isLoading}/>
    </Card>
  )
}

export default FolderManagementPage;