import {DataTable, DataTableExpandedRows, DataTableValueArray} from "primereact/datatable";
import { FolderProps } from "@service/FolderService/types";
import { Column } from "primereact/column";
import { FoldersEditionManagementPageRoute } from "../../pages/PagesRoutes";
import { useNavigate } from "react-router-dom";
import {FormEvent, useEffect, useState} from "react";
import ActionTemplate from "@components/FoldersDataTable/ActionTemplate";
import FolderTemplate from "@components/FoldersDataTable/FolderTemplate";
import FilterByName from "../../pages/Management/FolderManagementPage/FilterByName";

type Props = {
  folders: FolderProps[];
  loading: boolean;
}

export interface FolderTableProps extends FolderProps {
  children?: FolderProps[];
  filtered?: boolean;
}

const DEFAULT_SORT_FIELD = "name";
const DEFAULT_SORT_ORDER = 1;

const FoldersDataTable = ({ folders, loading }: Props) => {
  const navigate = useNavigate();
  const [mountedFolderList, setMountedFolderList] = useState<FolderTableProps[]>([]);
  const [folderListTableToRender, setFolderListTableToRender] = useState<FolderTableProps[]>([]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const [list, setList] = useState<FolderTableProps[]>([]);

  const handleSubmitFilterFolder = (event: FormEvent<HTMLFormElement>, folderName: string) => {
    event.preventDefault();

    setExpandedRows([]);
    setList([]);
    filterFolder(mountedFolderList, folderName);
  }

  const filterFolder = (folders: FolderTableProps[], folderName: string) => {
    folders.forEach(item => {
      if(item.name.toLowerCase().indexOf(folderName.toLowerCase()) > -1) {
        item.filtered = folderName.length > 0;

        if(item.folderParentId) {
          const folderParent = getRootFolderByParentId(item.folderParentId);
          if(folderParent) {
            setExpandedRows(oldState => Array.isArray(oldState) ? [...oldState, folderParent] : []);
            setList(oldState => ([...oldState, folderParent]));
            return;
          }
        } else if(!item.folderParentId) {
          setList(oldState => ([...oldState, item]));
        }
        return;
      }

      else if(item.children && item.children.length > 0) {
        filterFolder(item.children, folderName);
      }

      return setFolderListTableToRender([]);
    })
  }

  const getRootFolderByParentId = (parentId?: number): FolderTableProps | undefined => {
    const parent = folders.find(f => f.id === parentId);
    if(parent && parent.folderParentId) {
      setExpandedRows(oldState => Array.isArray(oldState) ? [...oldState, parent] : []);
      return getRootFolderByParentId(parent.folderParentId);
    } else {
      return mountedFolderList.find(folder => folder.id === parent?.id);
    }
  }

  const mountFolderListToRender = () => {
    if (folders) {
      return putChildFoldersInsideParent([...folders]);
    }
    return [];
  }

  const putChildFoldersInsideParent = (folderList: FolderTableProps[]) => {
    folderList.forEach((folder) => {
      folder.children = folderList.filter((item) => {
        if (item.folderParentId && folder.id) {
          if (folder.id !== item.id && item.folderParentId === folder.id) {
            return item;
          }
        }
        return null;
      })
    })

    let i = folderList.length;
    while (i--) {
      if (folderList[i].folderParentId) {
        folderList.splice(i, 1);
      }
    }

    return folderList;
  }

  const folderDataTable = (data: FolderTableProps) => {
    return (
      <>
        {data.children && (
          <DataTable
            value={data.children}
            className="p-datatable-sm"
            rowHover
            emptyMessage="Nenhuma pasta encontrada."
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={folderDataTable}
            expandedRows={expandedRows}
            loading={loading}
            showGridlines
            rowClassName={() => "cursor-pointer"}
            onRowClick={(e) => navigate(`${FoldersEditionManagementPageRoute}/${e.data.id}`)}
          >
            <Column expander className="w-1rem"/>
            <Column field="name"
                    body={(event) => (
                      <FolderTemplate folder={event} expandedRows={expandedRows}/>
                    )}
            />
            <Column
              className="w-1rem"
              body={(event) => (
                <ActionTemplate selectedFolderInTable={event}/>
              )}
            />
          </DataTable>
        )}
      </>
    )
  }

  useEffect(() => {
    const mountedFolders = mountFolderListToRender();
    setMountedFolderList(mountedFolders);
    setFolderListTableToRender(mountedFolders);
  }, [folders]);

  useEffect(() => {
    if(list.length > 0) {
      setFolderListTableToRender([...list]);
    }
  }, [list]);

  return (
    <>
      <FilterByName handleSubmitFilterFolder={handleSubmitFilterFolder}/>

      <DataTable
        value={folderListTableToRender}
        className="p-datatable-sm"
        rowHover
        emptyMessage="Nenhuma pasta encontrada."
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={folderDataTable}
        expandedRows={expandedRows}
        sortField={DEFAULT_SORT_FIELD}
        sortOrder={DEFAULT_SORT_ORDER}
        loading={loading}
        showGridlines
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rows={10}
        currentPageReportTemplate="Mostrando {last} de {totalRecords} pastas."
        rowClassName={() => "cursor-pointer"}
        onRowClick={(e) => navigate(`${FoldersEditionManagementPageRoute}/${e.data.id}`)}
      >
        <Column expander className="w-1rem"/>
        <Column
          header="Nome"
          sortable
          field="name"
          body={(event) => (
            <FolderTemplate folder={event} expandedRows={expandedRows}/>
          )}
        />
        <Column
          header="Ações"
          className="w-1rem"
          body={(event) => (
            <ActionTemplate selectedFolderInTable={event}/>
          )}
        />
      </DataTable>
    </>
  )
}

export default FoldersDataTable;