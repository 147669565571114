import React, {useEffect, useState} from 'react';
import EmptyTable from "@components/SimpleDocumentDataTable/EmptyTable";
import styles from "./OwnersDataTable.module.css";
import {Column, ColumnFilterElementTemplateOptions} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {OwnerProps, OwnerTypeI} from "@service/OwnerService/types";
import {getOwnerTypes} from "@service/OwnerService";
import {useKeycloak} from "@react-keycloak/web";
import {MultiSelect} from "primereact/multiselect";
import { FilterMatchMode} from "primereact/api";

type Props = {
    owners: OwnerProps[];
    isLoading: boolean;
};

const OwnersDataTable = ({owners, isLoading}: Props) => {
    const [ownerTypes, setOwnerTypes] = useState<OwnerTypeI[]>([]);
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if(keycloak.token) {
            getOwnerTypes().then(data => {
                setOwnerTypes(data);
            });
        }
    }, [keycloak.token]);

    const DEFAULT_SORT_FIELD = "name";
    const DEFAULT_SORT_ORDER = 1; // asc

    const ownerTypeRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        console.log('options', options);
        return (
            <MultiSelect
                value={options.value}
                options={ownerTypes}
                itemTemplate={(ownerType: OwnerTypeI) => ownerType.name}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                placeholder="Filtro por tipo"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '100%' }}
            />
        );
    };

    const filters = {
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        registryNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        type: { value: null, matchMode: FilterMatchMode.IN }
    }

    return <DataTable value={owners} stripedRows paginator rows={10} loading={isLoading} rowHover
                      className="p-datatable-sm"
                      // rowClassName={() => "cursor-pointer"}
                      emptyMessage={() => <EmptyTable />}
                      sortField={DEFAULT_SORT_FIELD}
                      sortOrder={DEFAULT_SORT_ORDER}
                      // onRowClick={onRowTableClick}
                      filterDisplay="row"
                      filters={filters}
                      tableClassName={styles.table}>
        <Column sortable filter filterPlaceholder="Filtro por nome" field="name" header="Nome" body={(owner: OwnerProps) => owner.name} />
        <Column filter filterField="type" showFilterMenu={false} filterElement={ownerTypeRowFilterTemplate} sortable field="type.name" header="Tipo" body={(owner: OwnerProps) => owner.type?.name} />
        <Column field="cpfCnpj" header="CPF/CNPJ" body={(owner: OwnerProps) => owner.cpf || owner.cnpj} />
        <Column sortable filter filterPlaceholder="Filtro por e-mail" field="email" header="E-mail" body={(owner: OwnerProps) => owner.email} />
        <Column sortable filter filterPlaceholder="Filtro por telefone" field="phone" header="Telefone" body={(owner: OwnerProps) => owner.phone} />
        <Column sortable filter filterPlaceholder="Filtro por registro" field="registryNumber" header="Registro" body={(owner: OwnerProps) => owner.registryNumber} />
    </DataTable>
};

export default OwnersDataTable;