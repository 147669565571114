import { useMutation } from "@tanstack/react-query";
import {
  createFolder,
  deleteFolder,
  getFolderById,
  getFolderByQuery,
  getSubFoldersByFolderParentId,
  updateFolder
} from "../../index";
import {queryClient} from "@others/utils/queryClients";

export const useGetFolderByFilter = () => useMutation(getFolderByQuery);

export const useGetFolderById = () => useMutation(getFolderById);

export const useCreateFolder = () => useMutation(createFolder, {
  onSuccess: () => {
    return queryClient.resetQueries(['folders']);
  }
});

export const useUpdateFolder = () => useMutation(updateFolder, {
  onSuccess: () => {
    return queryClient.resetQueries(['folders']);
  }
});

export const useGetSubFolders = () => useMutation(['folderList'], getSubFoldersByFolderParentId, {
  cacheTime: 1000 * 60 * 5
})

export const useDeleteFolder = () => useMutation(deleteFolder);