import { Calendar } from "primereact/calendar";
import { Dispatch, SetStateAction, useState } from "react";
import { SearchDocumentProps } from "@service/DocumentService/types";
import { Nullable } from "primereact/ts-helpers";

interface SearchDateFilterProps {
  classNames: string;
  placeholder: string;
  setSearchPageData: Dispatch<SetStateAction<SearchDocumentProps>>;
  searchPageData: SearchDocumentProps;
}

const SearchDateFilter = ({ classNames, placeholder, setSearchPageData }: SearchDateFilterProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState<any>();

  const onSetDateInSearch = (date: Nullable<(Date | null)[]>) => {
    setSelectedDateRange(date);
    if(Array.isArray(date)) {
      setSearchPageData((state) =>  (
        {
          ...state,
          documentDateStart: date[0]?.toISOString().split("T")[0],
          documentDateEnd: date[1]?.toISOString().split("T")[0]
        }
      ))
    }
  }

  const onResetDateSearch = () => {
    setSearchPageData((state) => (
      {
        ...state, documentDateStart: "", documentDateEnd: ""
      }
    ))
  }

  // const footerSelectionDateTemplate = () => {
  //   return (
  //     <div className="grid">
  //       <div className="col-12 font-bold">Tipo de data:</div>
  //       <div className="col-12 md:col-4">
  //         <RadioButton className="mr-1"/>
  //         <label>Documento</label>
  //       </div>
  //       <div className="col-12 md:col-4">
  //         <RadioButton className="mr-1"/>
  //         <label>Inserção</label>
  //       </div>
  //       <div className="col-12 md:col-4">
  //         <RadioButton className="mr-1"/>
  //         <label>Vencimento</label>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className={"p-fluid " + classNames}>
      <Calendar
        // footerTemplate={footerSelectionDateTemplate}
        placeholder={placeholder}
        className="w-full"
        locale="pt-BR"
        value={selectedDateRange}
        showButtonBar
        todayButtonClassName="hidden"
        dateFormat="dd/mm/yy"
        showIcon
        onClearButtonClick={onResetDateSearch}
        selectionMode="range"
        onChange={({value}) => onSetDateInSearch(value)}
        readOnlyInput
      />
    </div>
  )
}

export default SearchDateFilter;