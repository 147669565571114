import { TieredMenu } from "primereact/tieredmenu";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { MenuItem } from "primereact/menuitem";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useDeleteFolder } from "@service/FolderService/hooks/useFolderMutation";
import { useAppContext } from "@others/context/AppContext";
import { FolderProps } from "@service/FolderService/types";
import { queryClient } from "@others/utils/queryClients";

type Props = {
    selectedFolderInTable: FolderProps
}

const ActionTemplate = ({ selectedFolderInTable }: Props) => {
    const tieredMenuOptionsRef = useRef<TieredMenu>(null);
    const [isVisibleDeleteProductDialog, setIsVisibleDeleteProductDialog] = useState<boolean>();
    const { toastRefs } = useAppContext();

    const { mutate: mutateDeleteFolder } = useDeleteFolder();

    const buttonItems = (): MenuItem[] => {
        return [
            {
                label: 'Excluir',
                command: () => setIsVisibleDeleteProductDialog(true)
            }
        ];
    };

    const handleDeleteFolder = () => {
        mutateDeleteFolder(selectedFolderInTable.id, {
            onError: (error: any) => {
                toastRefs?.current?.show({ summary: "Erro", detail: error.response.data.message, severity: "error" });
            },
            onSuccess: () => {
                toastRefs?.current?.show({ summary: "Sucesso", detail: "Pasta excluída com sucesso.", severity: "success" });
                setIsVisibleDeleteProductDialog(true);
                queryClient.resetQueries(['folders']);
            }
        })
        setIsVisibleDeleteProductDialog(false);
    }

    return (
        <div>
            <TieredMenu ref={tieredMenuOptionsRef} popup model={buttonItems()} />
            <Button
                className="p-button-sm text-md h-2rem"
                icon="pi pi-bars"
                onClick={(event) => tieredMenuOptionsRef.current?.toggle(event)}
            />

            <ConfirmDialog
                onHide={() => setIsVisibleDeleteProductDialog(false)}
                visible={isVisibleDeleteProductDialog}
                acceptLabel="Confirmar"
                accept={handleDeleteFolder}
                rejectLabel="Cancelar"
                closeOnEscape
                reject={() => setIsVisibleDeleteProductDialog(false)}
                rejectClassName="p-button-danger p-button-outlined"
                message="Confirmar exclusão da pasta?"
                header="Exclusão"
            />
        </div>
    )
}

export default ActionTemplate;