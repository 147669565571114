import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import 'primeflex/primeflex.css';
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@others/utils/queryClients";
import MenuBar from "./components/MenuBar";
import { useKeycloak } from "@react-keycloak/web";
import { setAxiosToken } from "./lib/axios";
import './App.module.css';
import { AppContextProvider } from "@others/context/AppContext";

function App() {
  const { keycloak } = useKeycloak();

  setAxiosToken(keycloak.token);

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
         <BrowserRouter>
           <MenuBar />
           <Router />
         </BrowserRouter>
      </AppContextProvider>
    </QueryClientProvider>
  );
}

export default App;