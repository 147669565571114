import { DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import {FolderTableProps} from "@components/FoldersDataTable";
import {classNames} from "primereact/utils";

type Props = {
  folder: FolderTableProps;
  expandedRows: DataTableExpandedRows | DataTableValueArray | undefined
}
const FolderTemplate = ({ folder, expandedRows }: Props) => {
  return (
    <div>
      <i className={Array.isArray(expandedRows) && expandedRows.includes(folder) ? "pi pi-folder-open" : "pi pi-folder" }></i>
      <span className={"ml-1 " + classNames(folder.filtered === true ? "font-bold" : "")}>{folder.name}</span>
    </div>
  )
}

export default FolderTemplate;