import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FormEvent, useState } from "react";

type Props = {
  handleSubmitFilterFolder: (event: FormEvent<HTMLFormElement>, name: string) => void
}
const FilterByName = ({ handleSubmitFilterFolder }: Props) => {
  const [folderName, setFolderName] = useState<string>("");

  return (
    <form className="p-inputgroup flex-1 my-2" onSubmit={(e) => handleSubmitFilterFolder(e, folderName)}>
      <InputText
        placeholder="Filtre por nome"
        onChange={(event) => setFolderName(event.target.value)}
        value={folderName}
      />
      <Button icon="pi pi-search" type="submit"/>
    </form>
  )
}

export default FilterByName;