import { Card } from "primereact/card";
import PageTitle from "@components/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateFolder, useGetFolderById, useUpdateFolder } from "@service/FolderService/hooks/useFolderMutation";
import {useEffect, useState} from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FoldersManagementPageRoute } from "../../../PagesRoutes";
import { queryClient } from "@others/utils/queryClients";
import * as zod from "zod";
import { FolderEditionFormSchema } from "@service/FolderService/schemas";
import { useForm } from "react-hook-form";
import FolderTreeSelectionAllFolders from "@components/FolderTreeSelectionAllFolders";
import { FolderProps } from "@service/FolderService/types";
import { useKeycloak } from "@react-keycloak/web";

export type FolderEditionFormInput = zod.infer<typeof FolderEditionFormSchema>

const FolderEditionManagementPage = () => {
  const { id } = useParams();
  const folderId = Number(id);
  const navigate = useNavigate();
  const { initialized: isKeycloakInitialized } = useKeycloak();
  const { handleSubmit, register, reset, formState: { isDirty }, setValue } = useForm<FolderEditionFormInput>()
  const [parentFolderSelected, setParentFolderSelected] = useState<FolderProps>();

  const { mutateAsync: mutateFolderById, isLoading: isLoadingFolder } = useGetFolderById();
  const { mutateAsync: mutateCreateFolder, isLoading: isLoadingCreateFolder } = useCreateFolder();
  const { mutateAsync: mutateUpdateFolder, isLoading: isLoadingUpdateFolder } = useUpdateFolder();
  const isLoading = isLoadingFolder || isLoadingCreateFolder || isLoadingUpdateFolder;

  const onSubmitFolderForm = (folderInput: FolderEditionFormInput) => {
    if (!isDirty) return navigate(FoldersManagementPageRoute);

    if (folderId) {
      mutateUpdateFolder({folderId, folder: folderInput}).then(() => {
        queryClient.resetQueries(['folders']);
        navigate(FoldersManagementPageRoute);
      })
    } else {
      mutateCreateFolder(folderInput).then(() => {
        queryClient.resetQueries(['folders']);
        navigate(FoldersManagementPageRoute);
      })
    }
  }

  useEffect(() => {
    if (folderId && isKeycloakInitialized) {
      mutateFolderById(folderId).then((data) => {
        reset(data);
        if(data.folderParentId) {
          mutateFolderById(data.folderParentId).then((parentFolder) => {
              setParentFolderSelected(parentFolder);
          })
        }
      });
    }
  }, [folderId, isKeycloakInitialized]);

  return (
    <Card>
      <PageTitle title={folderId ? "Edição de Pasta" : "Criação de Pasta"}/>

      <form className="grid" onSubmit={handleSubmit(onSubmitFolderForm)}>
        <div className="md:col-4 col-12 justify-content-center flex">
          <span className="bi bi-folder line-height-1" style={{fontSize: "25vw"}}/>
        </div>
        <div className="md:col-8 col-12">
          <div className="field p-fluid">
            <label>Nome*</label>
            <InputText {...register("name")} />
          </div>
          <FolderTreeSelectionAllFolders
            selectionMode="single"
            placeholder="Vazio para pasta primária"
            formSelectedFolder={parentFolderSelected}
            onSelectFolderInTree={(folder: FolderProps) => setValue("folderParentId", folder.id)}
            label="Caminho"
            className="field"
          />
        </div>


        <div className="grid flex justify-content-end col-12 p-fluid pb-0">
          <div className="col-12 md:col-3">
            <Button
              label="Salvar"
              icon="bi-save"
              loading={isLoading}
            />
          </div>
          <div className="col-12 md:col-3">
            <Button
              label="Cancelar"
              icon="bi-x-lg"
              className="p-button-danger p-button-outlined"
              loading={isLoading}
              type="button"
              onClick={() => navigate(FoldersManagementPageRoute)}
            />
          </div>
        </div>
      </form>
    </Card>
  )
}

export default FolderEditionManagementPage;