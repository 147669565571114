import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";
import {Dispatch, SetStateAction} from "react";
import {Button} from "primereact/button";
import {Chips} from "primereact/chips";
import {SearchDocumentProps} from "../../service/DocumentService/types";
import {RadioButton} from "primereact/radiobutton";

interface MoreOptionsToSearchProps {
  setIsMoreOptionsDialogVisible: Dispatch<SetStateAction<boolean>>,
  isMoreOptionsDialogVisible: boolean;
  searchPageData: SearchDocumentProps;
  setSearchPageData: Dispatch<SetStateAction<SearchDocumentProps>>;
  refetchSearchData: () => void;
}

const MoreOptionsToSearch = ({ setIsMoreOptionsDialogVisible, isMoreOptionsDialogVisible, searchPageData, setSearchPageData, refetchSearchData }: MoreOptionsToSearchProps) => {

  const documentDateTypeOptions = [
    { label: "Documento" },
    { label: "Inserção" },
    { label: "Vencimento" }
  ]

  const documentStatusOptions = [
    { label: "Check-In" },
    { label: "Check-Out" }
  ]

  const documentPrivacyOptions = [
    { label: "Privado" },
    { label: "Não Privado" }
  ]

  const documentReadOnlyOptions = [
    { label: "Sim" },
    { label: "Não" }
  ]

  const refetchDataAndCloseDialog = () => {
    setIsMoreOptionsDialogVisible(false);
    refetchSearchData();
  }

  const handleDialogMoreOptionsFooter = () => {
    return (
      <div className="grid mt-2">
        <div  className="md:col-6 col-12">
          <Button label="Confirmar" icon="pi pi-check" className="w-full" onClick={() => refetchDataAndCloseDialog()}/>
        </div>
        <div  className="md:col-6 col-12">
          <Button className="p-button-danger w-full" label="Cancelar" icon="pi pi-times" onClick={() => setIsMoreOptionsDialogVisible(false)}/>
        </div>
      </div>
    )
  }

  const onSetDateInSearch = (key: string, date?: string | Date | Date[] | null) => {
    const selectedDate = new Date(date!.toString())
    selectedDate.setDate(selectedDate.getDate() + 1);

    setSearchPageData((state) =>  (
      {
        ...state,
        [key]: selectedDate.toISOString().split("T")[0]
      }
    ))
  }

  const formatDateToCalendar = (date: string | Date | Date[] | undefined) => {
    return date ? new Date(date.toString()) : null
  }

  return (
    <Dialog
      onHide={() => setIsMoreOptionsDialogVisible(false)}
      position="right"
      footer={handleDialogMoreOptionsFooter}
      draggable={false}
      header="Mais Opções"
      visible={isMoreOptionsDialogVisible}
      className="md:w-8 w-max"
    >
      <div className="grid p-fluid">
        <div className="field lg:col-6 col-12">
          <label>Tipo de Data:</label>
          <div
            className="grid align-items-center border-1 border-300 p-1 border-round justify-content-center"
            style={{ marginRight: "1px", marginLeft: "1px", marginTop: "1px" }}
          >
            {documentDateTypeOptions.map(dateType => {
              return (
                <div key={dateType.label} className="xl:col-6 col-12">
                  <RadioButton className=""/>
                  <label className="ml-1">{dateType.label}</label>
                </div>
              )
            })}
          </div>
        </div>

        <div className="field lg:col-3 col-12">
          <label>Inicial</label>
          <Calendar
            showIcon
            dateFormat="dd/mm/yy"
            value={formatDateToCalendar(searchPageData.documentDateStart)}
            onChange={({value}) => onSetDateInSearch("documentDateStart", value)}
          />
        </div>
        <div className="field lg:col-3 col-12">
          <label>Final</label>
          <Calendar
            showIcon
            dateFormat="dd/mm/yy"
            value={formatDateToCalendar(searchPageData.documentDateEnd)}
            onChange={({value}) => onSetDateInSearch("documentDateEnd", value)}
          />
        </div>
        <div className="field lg:col-6 col-12">
          <label>Estado do Documento</label>
          <div
            className="grid align-items-center border-1 border-300 p-1 border-round"
            style={{ marginRight: "1px", marginLeft: "1px", marginTop: "1px" }}
          >
            {documentStatusOptions.map(dateType => {
              return (
                <div key={dateType.label} className="lg:col-6 col-12">
                  <RadioButton className=""/>
                  <label className="ml-2">{dateType.label}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div className="field lg:col-6 col-12">
          <label>Privacidade do Documento</label>
          <div
            className="grid align-items-center border-1 border-300 p-1 border-round"
            style={{ marginRight: "1px", marginLeft: "1px", marginTop: "1px" }}
          >
            {documentPrivacyOptions.map(dateType => {
              return (
                <div key={dateType.label} className="lg:col-6 col-12">
                  <RadioButton className=""/>
                  <label className="ml-2">{dateType.label}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div className="field lg:col-6 col-12">
          <label>Somente Leitura</label>
          <div
            className="grid align-items-center border-1 border-300 p-1 border-round"
            style={{ marginRight: "1px", marginLeft: "1px", marginTop: "1px" }}
          >
            {documentReadOnlyOptions.map(dateType => {
              return (
                <div key={dateType.label} className="lg:col-6 col-12">
                  <RadioButton className=""/>
                  <label className="ml-2">{dateType.label}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div className="field lg:col-6 col-12">
          <label>Palavras-chave</label>
          <Chips/>
        </div>
        <div className="field lg:col-6 col-12">
          <label>Extensões</label>
          <InputText/>
        </div>
        <div className="field lg:col-6 col-12">
          <label>Fluxo de Documentos</label>
          <InputText/>
        </div>
        <div className="field col-12">
          <label>OCR (busca no conteúdo do documento - Separado por ;)</label>
          <InputText/>
        </div>
      </div>
    </Dialog>
  )
}

export default MoreOptionsToSearch;
