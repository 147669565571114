import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import keycloak from "./auth/Keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={{onLoad: "login-required", checkLoginIframe: false}}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
