import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";
import { useFormContext, Controller } from "react-hook-form";
import { PreviewDocumentFormInput } from "./index";
import { Calendar } from "primereact/calendar";
import FieldFillingErrorWarning from "@components/FieldFillingErrorWarning";
import OwnerAutocompleteSelection from "@components/OwnerAutocompleteSelection";
import { useEffect, useState } from "react";
import { usePreviewDocumentContext } from "@others/context/PreviewDocumentContext";
import { formatDocumentNameRemovingExtension } from "@others/utils/formatters";
import { OwnerProps } from "@service/OwnerService/types";
import DocumentTypeAndFieldsSelection from "@components/PreviewDocument/DocumentTypeAndFieldsSelection";
import RequiredFieldIcon from "@components/RequiredFieldIcon";
import {InputSwitch} from "primereact/inputswitch";
import FolderTreeSelectionAllFolders from "@components/FolderTreeSelectionAllFolders";

interface UserInfo {
  email: string;
  id: number;
  name: string;
}

const DocumentData = () => {
  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<PreviewDocumentFormInput>();

  const {
    requestedDocumentData,
    setDocumentTypeFilteredFromList,
    documentTypeFilteredFromList
  } = usePreviewDocumentContext();
  const [folderDocument, setFolderDocument] = useState<any>();
  const [ownerDocument, setOwnerDocument] = useState<OwnerProps>();
  const [documentTypeDocument, setDocumentTypeDocument] = useState<any>();

  const onSelectFolder = (value: any) => {
    if(value) {
      setValue("folder", value, {shouldDirty: true});
      setValue("folderId", value.id, {shouldDirty: true});
    }
  }

  const onSelectOwner = (value: OwnerProps) => {
    if(value) {
      setValue("owner", value, { shouldDirty: true });
      setValue("ownerId", value.id, { shouldDirty: true });
    }
  }

  const onSelectDocumentType = (value: any) => {
    if(value) {
      setDocumentTypeFilteredFromList(value);
      setValue("documentType", value, { shouldDirty: true });
    }
  }

  const defineInitialValue = (data: UserInfo | undefined, valueName: "checkout" | "makePrivate" | "readOnly") => {
    data !== null && data !== undefined ? setValue(valueName, true) : setValue(valueName, false);
  }

  useEffect(() => {
    setFolderDocument(getValues('folder'));
    setOwnerDocument(getValues('owner'));
    setDocumentTypeDocument(getValues('documentType'));
    reset(getValues());
  }, [])

  useEffect(() => {
    onSelectFolder(getValues("folder"));
    onSelectOwner(getValues("owner"));
    onSelectDocumentType(getValues("documentType"));
  }, [])

  useEffect(() => {
    defineInitialValue(requestedDocumentData?.checkoutBy, "checkout");
    defineInitialValue(requestedDocumentData?.privateBy, "makePrivate");
    defineInitialValue(requestedDocumentData?.readOnlyBy, "readOnly");
  }, [requestedDocumentData])

  useEffect(() => {
    if(requestedDocumentData && requestedDocumentData.validUntil) {
      setValue("validUntil", new Date(requestedDocumentData.validUntil));
      reset(getValues());
    }
  }, [requestedDocumentData])

  return (
    <div className="grid">
      <div className="field py-0 p-fluid col-12">
        <label>Nome do Documento</label>
        <Controller
          render={({ field: { value, onChange } }) => {
              return <InputText value={formatDocumentNameRemovingExtension(value)} onInput={onChange}/>
          }}
          name={"name"}
        />
      </div>

      <FolderTreeSelectionAllFolders
        label="Pasta"
        className="py-0 col-12 field"
        selectionMode="single"
        onSelectFolderInTree={onSelectFolder}
        formSelectedFolder={folderDocument}
      />

      <OwnerAutocompleteSelection
        multiple={false}
        label="Proprietário"
        placeholder="Selecione"
        className="py-0 col-12 field"
        onSelectOwner={onSelectOwner}
        formSelectedOwner={ownerDocument}
      />

      <DocumentTypeAndFieldsSelection
        onSelectDocumentType={onSelectDocumentType}
        documentTypeDocument={documentTypeDocument}
      />

      <div className="field py-0 p-fluid col-12">
        <label>Data Documento (Competência)</label>
        <Controller
          control={control}
          render={({ field: { value, onChange }} ) => {
            return (
              <Calendar
                dateFormat="dd/mm/yy"
                locale="pt-BR"
                value={new Date(value)}
                onChange={onChange}
              />
            )
          }}
          name={"documentDate"}
        />
      </div>

      {(documentTypeFilteredFromList && documentTypeFilteredFromList.validationRequired) &&
        <div className="field py-0 p-fluid col-12">
          <label>Validade do Documento</label><RequiredFieldIcon/>
          <Controller
            control={control}
            render={({ field: { value, onChange }} ) => {
              return (
                <>
                  <Calendar
                    dateFormat="dd/mm/yy"
                    locale="pt-BR"
                    value={value ? new Date(value) : null}
                    onChange={onChange}
                  />
                </>
              )
            }}
            name={"validUntil"}
          />
        </div>
      }

      <div className="field py-0 p-fluid col-12">
        <label>Propriedades Especiais</label>
        <div className="p-3 border-1 border-300 border-round">
          <Controller
            render={({ field: {value, onChange} }) => {
              return (
                <div className="grid mb-1 col-12">
                  <InputSwitch
                    inputId="checkoutInput"
                    checked={value}
                    className="mr-1"
                    onChange={onChange}
                  />
                  <label htmlFor="checkoutInput" style={{fontSize: "1.05rem"}}>Em Checkout</label>
                </div>
              )
            }}
            name={"checkout"}
          />

          <Controller
            control={control}
            render={({field: { value , onChange}}) => {
              return (
                <div className="grid my-1 col-12">
                  <InputSwitch
                    inputId="privateInput"
                    checked={value}
                    className="mr-1"
                    onChange={onChange}
                  />
                  <label htmlFor="privateInput" style={{fontSize: "1.05rem"}}>Privado</label>
                </div>
              )
            }}
            name={"makePrivate"}
          />

          <Controller
            control={control}
            render={({field: { value , onChange}}) => {
              return (
                <div className="grid mt-1 col-12">
                  <InputSwitch
                    inputId="ocrInput"
                    checked={value}
                    className="mr-1"
                    onChange={onChange}
                  />
                  <label htmlFor="ocrInput" style={{fontSize: "1.05rem"}}>OCR</label>
                </div>
              )
            }}
            name={"ocrEnabled"}
          />
        </div>
      </div>

      {/*<WorkflowDropdownSelection*/}
      {/*  className="py-0 col-12"*/}
      {/*  placeholder="Selecione"*/}
      {/*  disabledAndReadOnly={!isEditableDocument}*/}
      {/*/>*/}

      <div className="field py-0 p-fluid col-12">
        <label>Palavras-chave</label>
        <Controller
          render={({ field: { value, onChange }}) => (
            <Chips
              tooltipOptions={{ position: "bottom" }}
              tooltip="Pressione 'Enter' a cada nova tag."
              onChange={onChange}
              value={value}
            />
          )}
          name="keywords"
          control={control}
        />
      </div>

      <FieldFillingErrorWarning errors={errors}/>
    </div>
  )
}

export default DocumentData;