import React, {useEffect, useRef} from 'react';
import {LicenseI} from "../../service/LicenseService/types";
import {useKeycloak} from "@react-keycloak/web";
import {getLicense} from "../../service/LicenseService";
import { addLocale } from 'primereact/api';
import { localePtBrConfig } from "../../lib/primereact";
import {Toast} from "primereact/toast";

addLocale('pt-BR', localePtBrConfig());

interface AppContextI {
  license?: LicenseI;
}

const defaultValue: AppContextI = {
  license: undefined,
};

export interface AppContextValueI {
  currentValue: AppContextI;
  updateValue: (values: { [x: string]: any }) => void;
  toastRefs: React.RefObject<Toast> | null;
}

const AppContext = React.createContext<AppContextValueI>({
  currentValue: defaultValue,
  updateValue: () => {},
  toastRefs: null
});

export function useAppContext() {
  return React.useContext(AppContext);
}

interface Props {
  children: React.ReactNode;
}

export const AppContextProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [currentValue, setCurrentValue] = React.useState<AppContextI>(defaultValue);
  const keycloak = useKeycloak();
  const toastRefs = useRef<Toast>(null);

  useEffect(() => {
    if(keycloak.initialized) {
      getLicense().then(data => updateValue({license: data}));
    }
  }, [keycloak.initialized]);

  const updateValue = React.useCallback(
    (values: { [x in keyof AppContextI]: any }) => {
      setCurrentValue({ ...currentValue, ...values,});
    },
    [currentValue, setCurrentValue]
  );

  return (
    <AppContext.Provider value={{ currentValue, updateValue, toastRefs }}>
      {children}
      <Toast ref={toastRefs}/>
    </AppContext.Provider>
  );
};
