import {FolderEditionProps, FolderProps, FolderUpdateParams} from "./types";
import { api } from "../../lib/axios";

export const getAllFoldersByFilter = async (): Promise<FolderProps[]> => {
  const response = await api.get<FolderProps[]>('/folders');
  return response.data;
}

export const getAllFoldersByLicense = async(): Promise<FolderProps[]> => {
  const response = await api.get<FolderProps[]>('/folders/findAllByLicense');
  return response.data;
}

export const getRootFolders = async (): Promise<FolderProps[]> => {
  const response = await api.get<FolderProps[]>('folders/root')
  return response.data;
}

export const getFolderByQuery = async (name: string): Promise<FolderProps[]> => {
  const response = await api.get<FolderProps[]>('folders', { params: { name } })
  return response.data;
}

export const getFolderById = async (folderId: number): Promise<FolderProps> => {
  const response = await api.get<FolderProps>('folders/' + folderId)
  return response.data;
}

export const getSubFoldersByFolderParentId = async (rootFolderIdList: number[]): Promise<FolderProps[]> => {
  let folders: FolderProps[] = []
  for (const folderId of rootFolderIdList) {
    const response = await api.get<FolderProps[]>(`folders/${folderId}/subfolders`)
    folders.push(...response.data)
  }
  return folders;
}

export const createFolder = async (folder: FolderEditionProps) => {
  const response = await api.post("folders", folder);
  return response.data;
}

export const updateFolder = async ({ folder, folderId }: FolderUpdateParams) => {
  const response = await api.put(`folders/${folderId}`, folder);
  return response.data;
}

export const deleteFolder = async (id: number): Promise<void> => {
  await api.delete("folders/" + id);
}